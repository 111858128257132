.header {
    z-index: 1;
    background: white;
    border-bottom: 1px solid #e8e8e8;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

.header-logo {
    width: 95px;
}

.language-select {
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.footer {
    z-index: 1;
    color: white;
    background: #0f334a;
}

.footer a {
    color: #fff;
    text-decoration: none;
}

.footer-container {
    padding: 10px;
}

.main {
    z-index: 0;
    flex: 1;
    padding: 20px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.main, .footer-container, .header-container {
    width: 100%;
    max-width: 1200px;
    margin: auto;
}

@media only screen and (max-width: 480px) {
    .header-container {
        padding: 10px;
    }

    .header-logo {
        width: 65px;
    }
}

.starRating:not(:checked) > div > div > label > input {
    position:absolute;
    clip:rect(0, 0, 0, 0);
}
.starRating:not(:checked) > div > div > label > span {
    float:right;
    width:1em;
    padding:0 .1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:220%;
    line-height:1.2;
    color:#a7a9ab;
    margin-right:10px
}
.starRating:not(:checked) > div > div > label > span:before {
    content:'★ ';
}
.starRating > div > div.radio-selected > label > input:checked + span {
    color: #0f334a;
}
.starRating > div > div.radio-selected ~ div > label > span {
    color: #0f334a;
}

.starRating:not(:checked) > div > div.radio-selected > label > span:hover,
.starRating:not(:checked) > div > div > label > input:hover + span,
.starRating:not(:checked)  > div > div:hover ~ div > label > span {
    color: #0f172b;
}
.starRating input:checked > div > div.radio-selected ~ div > label > span:hover,
.starRating input:checked > div > div ~ div > label > span:hover,
.starRating > div > div.radio-selected ~ div > label > input:checked + span:hover,
.starRating > div > div.radio-selected:hover ~ div > label > span:hover
{
    color: #0f172b;
}
.starRating > div > div > label:active {
    position:relative;
}

div.starRating > div {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}

div.starRating > label {
    font-size: x-large;
    font-weight: 600;
}

div.starRating > div.form-text.text-muted {
    font-size: 0.70em;
    padding-bottom: 20px;
}

div.starRating > div > div.form-check-inline {
    margin-right: 0;
}

div.ratingRadio > div > div > label {
    font-size: medium;
}

div.ratingRadio > div > div {
    text-align: center;
}

@media only screen and (max-width: 480px) {

    div.ratingRadio > div > div > label {
        font-size: unset;
        display: flex;
        align-items:center;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }

    div.ratingRadio>div>div {
        text-align: left;
        width: 250px;
        padding-left: 10px;
    }

    div.ratingRadio > div > div > label > span {
        display: unset;
        float: right;
        padding-left: 10px;
        padding-bottom: 5px;
    }

    div.ratingRadio>div>div>label>input {
        min-height: 1em;
        min-width: 1em;
    }

    div.ratingRadio {
        padding-top: 10px;
    }

    div.ratingRadio>label:after {
        top: calc(-10px - 1em);
    }

    td>div>div>div>b:after {
        content: " *";
        color: transparent;
    }
}

div.ratingRadio + div.required > div > textarea::placeholder {
    color: #dc3545;
}

div.ratingRadio + div > div > textarea::placeholder {
    font-size: small;
}

div.txtAreaPH > div > textarea::placeholder {
    font-size: small;
}

ul.formio-wizard-nav-container {
    padding-top: 10px;
}

table.ratingTable {
    --bs-table-striped-bg: rgb(15 51 74 / 2%);
}
