html {
    box-sizing: border-box;
    scroll-behavior: auto !important;
    font-size: 18px;
}

*, *:before, *:after {
    box-sizing: inherit;
    font-family: inherit;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
}

body {
    margin: 0;
    background: url("./assets/background_dark.jpg") #f5f5f5 no-repeat center center fixed;
    background-size: cover;
    color: #0f172b;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h2 {
    font-size: 32px;
    font-weight: bold;
}

h3 {
    font-size: 24px;
    font-weight: bold;
}

.main-heading {
    color: #ffffff;
    background-color: rgb(15, 51, 74);
    text-align: center;
}

.btn-primary {
    color: #ffffff;
    background-color: #004a6b;
    border-color: #004a6b;
}

.btn-primary:hover {
    color: #ffffff;
    background-color: #0f334a;
    border-color: #004a6b;
}

.btn-secondary {
    color: #004a6b;
    background-color: #ffffff;
    border-color: #004a6b;
}

.btn-secondary:hover {
    color: #004a6b;
    background-color: #eeeeee;
    border-color: #004a6b;
}
.page-item.active .page-link {
    color: #fff;
    background-color: #004a6b;
    border-color: #004a6b;
}

.page-link {
    color: #004a6b;
    background-color: #ffffff;
    border: 1px solid #004a6b;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.page-link:hover {
    color: #004a6b;
    background-color: #eeeeee;
    border-color: #004a6b;
}

div.nps > div:nth-child(2) > div:nth-child(1) > label:nth-child(1) > span:nth-child(1){
    color: red;
    font-weight: bold;
    font-size: larger;
}
div.nps > div:nth-child(2) > div:nth-child(2) > label:nth-child(1) > span:nth-child(1){
    color: red;
    font-weight: bold;
    font-size: larger;
}
div.nps > div:nth-child(2) > div:nth-child(3) > label:nth-child(1) > span:nth-child(1){
    color: red;
    font-weight: bold;
    font-size: larger;
}
div.nps > div:nth-child(2) > div:nth-child(4) > label:nth-child(1) > span:nth-child(1){
    color: red;
    font-weight: bold;
    font-size: larger;
}
div.nps > div:nth-child(2) > div:nth-child(5) > label:nth-child(1) > span:nth-child(1){
    color: red;
    font-weight: bold;
    font-size: larger;
}
div.nps > div:nth-child(2) > div:nth-child(6) > label:nth-child(1) > span:nth-child(1){
    color: orangered;
    font-weight: bold;
    font-size: larger;
}
div.nps > div:nth-child(2) > div:nth-child(7) > label:nth-child(1) > span:nth-child(1){
    color: orangered;
    font-weight: bold;
    font-size: larger;
}

div.nps > div:nth-child(2) > div:nth-child(8) > label:nth-child(1) > span:nth-child(1){
    color: orange;
    font-weight: bold;
    font-size: larger;
}

div.nps > div:nth-child(2) > div:nth-child(9) > label:nth-child(1) > span:nth-child(1){
    color: orange;
    font-weight: bold;
    font-size: larger;
}

div.nps > div:nth-child(2) > div:nth-child(10) > label:nth-child(1) > span:nth-child(1){
    color: green;
    font-weight: bold;
    font-size: larger;
}

div.nps > div:nth-child(2) > div:nth-child(11) > label:nth-child(1) > span:nth-child(1){
    color: green;
    font-weight: bold;
    font-size: larger;
}

div.nps .form-check-inline {
    margin-right: 0.3rem;
}
